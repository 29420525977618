<template>
  <div class="coupon-card">
    <DynamicCompose
      :createItem="createItem"
      :value="value"
      @input="$emit('input', $event)"
      :max="9"
      :min="min">
      <template #default="{ data, id, handleDelete, deleteDisabled }">
        <ykc-form class="coupon-card-form" :model="data" :rules="rules">
          <ykc-icon
            name="reduce"
            :class="{ disabled: deleteDisabled }"
            @click="deleteRow({ deleteDisabled, handleDelete, id })"></ykc-icon>
          <ykc-form-item prop="type">
            <ykc-dropdown
              v-model="data.type"
              :data="data.typeData"
              @change="handleTypeChange(false, data)"></ykc-dropdown>
          </ykc-form-item>
          <ykc-form-item prop="value" style="margin-left: 8px" :rules="data.rules">
            <ykc-dropdown
              v-model="data.value"
              :data="data.valueData"
              @change="handleValueChange"
              :placeholder="data.type === 'card' ? '请选择抵用卡' : '请选择优惠券'"></ykc-dropdown>
          </ykc-form-item>
        </ykc-form>
      </template>
      <template #add="{ handleAdd, reachMax }">
        <div class="coupon-card-add" @click="addItem({ handleAdd, reachMax })">
          <ykc-icon name="add"></ykc-icon>
          <span>添加优惠卡券</span>
        </div>
      </template>
    </DynamicCompose>
  </div>
</template>

<script>
  import DynamicCompose from './dynamic-compose.vue';

  export default {
    components: { DynamicCompose },
    props: {
      value: {
        type: Array,
        required: true,
      },
      min: {
        type: Number,
        default: 1,
        validator(val) {
          return val >= 0;
        },
      },
      totalCouponData: {
        type: Array,
        required: true,
      },
      totalCardData: {
        type: Array,
        required: true,
      },
    },
    watch: {
      totalCouponData: {
        deep: true,
        immediate: true,
        handler() {
          this.handleTypeChange(false);
        },
      },
      totalCardData: {
        deep: true,
        immediate: true,
        handler() {
          this.handleTypeChange(false);
        },
      },
    },
    computed: {
      rules() {
        return {
          type: [
            {
              required: true,
              message: '请选择类型',
              trigger: 'blur',
            },
          ],
        };
      },
    },
    created() {
      this.handleTypeChange(false);
    },
    methods: {
      deleteRow({ deleteDisabled, handleDelete, id }) {
        if (deleteDisabled) return;
        handleDelete(id);
        // 删除后,重新计算可用的下拉选项
        this.handleTypeChange(false);
      },
      createItem() {
        const item = {
          // 默认选择优惠券
          type: 'coupon',
          value: '',
          valueData: [],
          rules: [
            {
              required: true,
              message: '请选择优惠券',
              trigger: 'blur',
            },
          ],
          typeData: [
            {
              id: 'coupon',
              name: '优惠券',
            },
            {
              id: 'card',
              name: '抵用卡',
            },
          ],
        };
        this.calculateAvailableCouponCardData(item);
        return item;
      },
      calculateAvailableCouponCardData(current, validate = false) {
        this.value.forEach(item => {
          this.$set(item, 'rules', [
            {
              required: true,
              trigger: 'blur',
              message: item.type === 'coupon' ? '请选择优惠券' : '请选择抵用卡',
            },
          ]);
          this.$set(item, 'typeData', [
            {
              id: 'coupon',
              name: '优惠券',
            },
            {
              id: 'card',
              name: '抵用卡',
            },
          ]);
        });
        const used = this.value.filter(item => item !== current);
        const usedCoupons = used.filter(item => item.type === 'coupon').map(item => item.value);
        const usedCards = used.filter(item => item.type === 'card').map(item => item.value);
        const availableCouponsData = this.totalCouponData.filter(
          ({ id }) => !usedCoupons.includes(id)
        );
        const availableCardsData = this.totalCardData.filter(({ id }) => !usedCards.includes(id));
        if (current.type === 'coupon') {
          if (availableCouponsData.length === 0) {
            current.value = '';
          }
          this.$set(current, 'valueData', availableCouponsData);
        }
        if (current.type === 'card') {
          if (availableCardsData.length === 0) {
            current.value = '';
          }
          this.$set(current, 'valueData', availableCardsData);
        }
        !!validate && this.$nextTick(this.validate);
      },
      handleValueChange() {
        this.$nextTick(() => {
          this.value.forEach(item => {
            this.calculateAvailableCouponCardData(item, true);
          });
        });
      },
      handleTypeChange(validate = false, data = null) {
        if (data) {
          data.value = '';
        }
        this.$nextTick(() => {
          this.value.forEach(item => {
            this.calculateAvailableCouponCardData(item, validate);
          });
        });
      },
      validate() {
        const ykcFormVms = [...this.$el.querySelectorAll('.ykc-form.coupon-card-form')].map(
          // eslint-disable-next-line
          node => node.__vue__
        );
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });
        return new Promise(resolve => {
          Promise.all(ykcFormVms.map(promisify)).then(res => {
            resolve(res.every(valid => !!valid));
          });
        });
      },
      addItem({ handleAdd, reachMax }) {
        if (reachMax) {
          // 这里可以给出提示
          return;
        }
        this.validate().then(valid => {
          if (valid) {
            handleAdd();
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  .coupon-card {
    color: inherit;
    &-form {
      display: flex;
      .ykc-icon-reduce {
        width: 30px;
        text-align: right;
        height: 16px;
        margin-top: 7px;
        margin-right: 8px;
        color: var(--theme-color-primary);
        &.disabled {
          color: #d7d7d7;
          cursor: not-allowed;
        }
      }
    }
    // 处理4.0样式干扰
    & .el-form-item.is-success {
      .ykc-input.el-input {
        .el-input-group__prepend {
          border: 1px solid #d7d7d7 !important;
          border-right: 0 !important;
        }
        .el-input__inner {
          border: 1px solid #d7d7d7 !important;
        }
      }
      .ykc-dropdown-box {
        .el-input-group__prepend {
          border: 1px solid #d7d7d7 !important;
          border-right: 0 !important;
        }
        .el-input__inner {
          border: 1px solid #d7d7d7 !important;
        }
      }
    }
    &-add {
      cursor: pointer;
      font-size: 12px;
      // font-family: PingFangSC-Medium, PingFang SC;
      // font-weight: 500;
      // line-height: 17px;
      color: var(--theme-color-primary);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      .ykc-icon-add {
        width: 30px;
        text-align: right;
        margin-right: 8px;
        color: var(--theme-color-primary);
      }
    }
  }
</style>
