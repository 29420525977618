<template>
  <ykc-form :model="model" ref="YkcForm" :rules="rules">
    <ykc-form-item label="等级数值" prop="level">
      <ykc-input v-model="model.level" disabled></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="等级名称" prop="levelName">
      <ykc-input v-model="model.levelName" disabled></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="升级条件" prop="upgradeCondition">
      <ykc-input v-model="model.upgradeCondition" disabled type="number">
        <template #prepend>成长值达到</template>
      </ykc-input>
    </ykc-form-item>

    <ykc-form-item label="会员折扣" prop="discountRatio">
      <div class="discountRatio">
        <ykc-dropdown
          v-model="model.discountType"
          :data="discountTypeData"
          :configSet="{ label: 'name', value: 'code' }"></ykc-dropdown>
        <ykc-input
          v-model="model.discountRatio"
          placeholder="请输入折扣比例"
          @change="
            val =>
              (model.discountRatio = Math.round(
                Number.isNaN(Number(val || 0)) ? 0 : Number(val || 0)
              ))
          "></ykc-input>
      </div>
    </ykc-form-item>
    <ykc-form-item label="升级礼包" prop="giftPackageName">
      <ykc-input v-model="model.giftPackageName" placeholder="请输入礼包名称">
        <template #prepend>礼包名称</template>
      </ykc-input>
      <CouponCard
        ref="CouponCard"
        style="margin-top: 16px"
        :min="0"
        v-model="model.couponCardData"
        :totalCouponData="totalCouponData"
        :totalCardData="totalCardData"></CouponCard>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { localGetItem } from '@/utils';
  import { marketManage } from '@/service/apis';

  import CouponCard from '@/components/dynamic-compose/coupon-card.vue';

  export default {
    components: { CouponCard },
    props: ['data', 'totalCouponData', 'totalCardData'],
    data() {
      return {
        model: null,
        discountTypeData: localGetItem('dictionary')?.member_discount_type || [],
      };
    },
    created() {
      this.initModel();
    },
    computed: {
      rules() {
        return {
          upgradeCondition: [
            {
              required: true,
              message: '请输入升级条件',
              trigger: 'blur',
            },
          ],
          discountRatio: [
            {
              required: true,
              message: '请输入会员折扣',
              trigger: 'blur',
            },
            {
              validator(_, value, cb) {
                value = Number(value || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (value < 0 || value > 100) {
                  return cb(new Error('折扣比例范围为0-100'));
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
          giftPackageName: [
            {
              required: true,
              message: '请输入升级礼包名称',
              trigger: 'blur',
            },
          ],
        };
      },
    },
    methods: {
      initModel() {
        const data = { ...this.data };
        const couponData = (this.data.couponIds || []).map(value => ({
          type: 'coupon',
          value,
        }));
        const cardData = (this.data.deductionCardIds || []).map(value => ({
          type: 'card',
          value,
        }));
        data.couponCardData = [...couponData, ...cardData];
        this.model = data;
      },
      submitForm() {
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });
        return new Promise(resolve => {
          Promise.all([promisify(this.$refs.YkcForm), this.$refs.CouponCard.validate()]).then(
            res => {
              if (res.every(valid => !!valid)) {
                const data = { ...this.model };
                data.couponIds = data.couponCardData
                  .filter(item => item.type === 'coupon')
                  .map(item => item.value);
                data.deductionCardIds = data.couponCardData
                  .filter(item => item.type === 'card')
                  .map(item => item.value);
                delete data.couponCardData;
                marketManage.memberLevelRuleConfig(data).then(response => {
                  resolve(response);
                });
              }
            }
          );
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .discountRatio {
    display: flex;
    flex-direction: row;
    .ykc-input {
      margin-left: 8px;
    }
  }
</style>
