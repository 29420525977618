<template>
  <scroll-layout>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())"></ykc-table>
    </div>
    <ykc-drawer
      title="会员等级配置"
      :show.sync="showDrawer"
      :on-close="onDrawerClose"
      :before-cancel="onDrawerCancel">
      <template #footer>
        <div style="padding-left: 16px">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button
              style="margin-right: 8px"
              :type="btn.type"
              v-if="btn.enabled()"
              :key="i"
              @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <EditConfig
          v-if="showDrawer"
          :totalCouponData="totalCouponData"
          :totalCardData="totalCardData"
          ref="EditConfig"
          :data="currentRow"></EditConfig>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { marketManage } from '@/service/apis';
  import EditConfig from './EditConfig.vue';
  import { code } from '@/utils';

  export default {
    name: 'ChargingPriceStrategyList',
    components: {
      EditConfig,
    },
    data() {
      return {
        totalCouponData: [],
        totalCardData: [],
        showDrawer: false,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.EditConfig.submitForm().then(() => {
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        tableData: [],
        currentRow: null,
        tableOperateButtons: [
          {
            enabled: () => code('operation:member:grade:setting'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => this.handleConfig(row)}>
                  配置
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          { label: '等级', prop: 'level' },
          { label: '等级名称', prop: 'levelName' },
          { label: '成长值', prop: 'growthValueRange' },
          { label: '升级礼包', prop: 'giftPackageName' },
          { label: '会员折扣', prop: 'discountDesc' },
          { label: '修改人', prop: 'modifiedAccount' },
          { label: '修改时间', prop: 'modifiedTime' },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          memberNo: '',
          userAccount: '',
          userName: '',
          userPhone: '',
          level: '',
        },
      };
    },
    created() {
      this.searchTableList();
    },
    methods: {
      handleConfig({ level }) {
        this.getCouponCardData();
        marketManage
          .findLevelInfoByLevel({
            level,
          })
          .then(data => {
            console.log('data', data);
            this.currentRow = data;
            this.showDrawer = true;
          });
      },
      getCouponCardData() {
        Promise.all(
          [
            {
              cardCouponType: '1', // 1抵用卡,2优惠券
              useType: '2', // 1普通,2会员
              undertakerType: '1', // 1平台,2商户
            },
            {
              cardCouponType: '2', // 1抵用卡,2优惠券
              useType: '2', // 1普通,2会员
              undertakerType: '1', // 1平台,2商户
            },
          ].map(marketManage.activityList)
        ).then(([cards, coupons]) => {
          console.log('getCouponCardData', cards, coupons);
          this.totalCardData = (cards || []).map(({ cardCouponId: id, cardCouponName: name }) => ({
            id,
            name,
          }));
          this.totalCouponData = (coupons || []).map(
            ({ cardCouponId: id, cardCouponName: name }) => ({
              id,
              name,
            })
          );
        });
      },
      searchTableList() {
        marketManage.memberLevelList({}).then(data => {
          console.log('memberLevelList data', data);
          this.tableData = data;
          this.total = data.length;
        });
      },
      onDrawerClose(done) {
        done();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 点击抽屉取消按钮 callback */
      onDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            // 点击确认取消保存后，要重置表单
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /** 获取勾选id */
      selectionChange(selection) {
        this.selectItems = selection.map(item => {
          return item.activityId;
        });
      },
    },
  };
</script>
